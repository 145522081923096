import { IndexPage, MetaData } from '@/components'
import { NextPage } from 'next'

const NextIndexPage: NextPage = () => {
  return (
    <>
      <MetaData />
      <IndexPage />
    </>
  )
}

export default NextIndexPage
